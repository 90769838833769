import React, { useCallback } from "react";
import Link from "next/link";
import Image from "next/image";
import style from "./error.module.scss";
import Typography from "../../higher-order-components/typography-hoc";
import logo from "../../images/logo-yellow.png";
import { getLocal } from "../../common/function/storage";
import {
  checkLogOrNot,
  deleteAllCookies,
} from "../../common/function/function";

const Index = ({ statusCode }) => {
  const _TOKEN = getLocal("access_token");

  const statusCodeCheck = useCallback(() => {
    switch (statusCode) {
      case 404: {
        return "404-Not Found";
      }
      case 403: {
        return "403-Forbidden";
      }
      case 500: {
        return "500-Internal Server Error";
      }
      case 502: {
        return "502-Bad Gateway";
      }
      case 503: {
        return "503-Service Unavailable";
      }
      case 400: {
        return "400-Bad Request";
      }
      case 405: {
        return "405-Method Not Allowed";
      }
      case 414: {
        return "414-URI Too Long";
      }
      case 416: {
        return "416-Range Not Satisfiable";
      }
      case 501: {
        return "501-Not Implemented";
      }
      case 504: {
        return "504-Gateway Timeout";
      }
    }
  });

  const statusCodeText = useCallback(() => {
    switch (statusCode) {
      case 404: {
        return "Oh no! The audio you were looking for has gone on an adventure without you. Please try a different search or check back later.";
      }
      case 403: {
        return "Sorry adventurer, you don't have permission to access this content. Please check with the dungeon master or contact our support team for assistance.";
      }
      case 500: {
        return "Uh-oh! It looks like the Bard's lute strings snapped. We're working to get the music back up and running. Please try again later.";
      }
      case 502: {
        return "The portal to the sound realm is currently closed. Please try again later or consult the magic user for assistance.";
      }
      case 503: {
        return "The marketplace is currently experiencing a goblin raid and is unable to process your request. Please try again later or arm yourself for battle.";
      }
      case 400: {
        return "Sorry adventurer, it looks like you made a wrong turn. Please double-check your request and try again.";
      }
      case 405: {
        return "You're not allowed to use that method to access this content. Please check with the dungeon master or contact our support team for assistance.";
      }
      case 414: {
        return "Your quest for this music is taking you to places unknown. Please try a different search or shorten your request.";
      }
      case 416: {
        return "It seems that the music you're looking for is out of range. Please try a different search or consult the Bard for assistance.";
      }
      case 501: {
        return "The feature you're requesting is not yet available in this realm. Please try again later or consult the wizard for assistance.";
      }
      case 504: {
        return "Our portal to the sound realm is taking longer than expected to respond. Please try again later or sharpen your blade for battle Action - Please clear your browser cache and cookies and reload this page.";
      }
    }
  });

  const homeDiv = () => {
    if (_TOKEN) {
      return (
        <span
          onClick={() => checkLogOrNot(_TOKEN)}
          className={`${style.button_div}`}
        >
          Home
        </span>
      );
    } else {
      return (
        <span
          onClick={() => checkLogOrNot(_TOKEN)}
          className={`${style.button_div}`}
        >
          Home
        </span>
      );
    }
  };

  return (
    <div className={`${style.error_sec_main_div} m-b-100`}>
      <div className="container">
        <div className={`${style.logo_div}`}>
          <Image
            src={logo}
            className="img-fluid"
            alt="logo"
            loading="eager"
            priority
          />
        </div>
        <div className={`${style.error_contain_div}`}>
          <Typography
            variant={"h3"}
            className={"m-b-20 font-weight-medium sub-title text-center"}
          >
            {statusCodeCheck()}
          </Typography>
          <Typography
            variant={"body1"}
            className={
              "line-height-36 m-b-20 fs-16 color-white opacity-0-7 common-contain-para text-center"
            }
          >
            {statusCodeText()}
          </Typography>
          {statusCode !== 404 ? (
            <Typography
              variant={"body1"}
              className={
                "line-height-36 m-b-20 fs-18 color-yellow common-contain-para text-center"
              }
            >
              What Can I do - Steps to resolve. Could be multiple lines
            </Typography>
          ) : null}
        </div>
        <div className={`${style.error_contact_div}`}>
          <div className={`${style.common_box}`}>
            <a
              className="fs-16 color-white opacity-0-7"
              href="mailto:gm@battlebards.com"
            >
              Contact Support : gm@battlebards.com
            </a>
          </div>
          <div className={`${style.common_box}`}>
            <a
              href="https://status.battlebards.com"
              className={`${style.button_div}`}
            >
              Status
            </a>
          </div>
          {statusCode === 504 || statusCode === 502 ? (
            <div className={`${style.common_box}`}>
              <a
                onClick={() => {
                  deleteAllCookies();
                }}
                href={"/"}
                className={`${style.button_div}`}
                style={{ cursor: "pointer" }}
              >
                Clear cookies
              </a>
            </div>
          ) : null}
          <div className={`${style.common_box}`}>{homeDiv()}</div>
        </div>
      </div>
    </div>
  );
};

export default Index;
