import React from "react";
import { useSelector } from "react-redux";
/* import Header from "../component/landing-page/header/header"; */
import Footer from "../component/landing-page/footer/footer";
import ErrorPage from "../component/error/index";
import MoreMenu from "../component/button-navigation/more-menu";
import useWindowDimensions from "../common/function/hooks/use-window-dimensions";

const Error = ({ statusCode }) => {
  const { width } = useWindowDimensions();
  let isMobile = width <= 767;
  const _OPEN_MORE_MENU = useSelector((state) => state.common.openMoreMenu);

  return (
    <div>
      {/*  <Header /> */}

      <div className="container">
        <ErrorPage statusCode={statusCode} />
      </div>

      {!isMobile && <Footer />}
      {_OPEN_MORE_MENU ? <MoreMenu /> : null}
    </div>
  );
};

Error.getInitialProps = ({ res, err }) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return { statusCode };
};

export default Error;
